import FormField from './formfield';

export default class ValidatedForm {
  public fields: { [key: string]: FormField } = {};

  constructor(formFields: { [key: string]: FormField }) {
    this.fields = formFields;
  }

  public formValid = () => {
    Object.values(this.fields).filter((field: FormField) => field.dirty = true);
    return this.getFormValid();
  }

  public getFormValid() {
    return Object.values(this.fields).filter((field: FormField) => field.validate() === false).length === 0;
  }

  public fieldValidity = () => {
    const result: { [key: string]: boolean | null } = {};
    Object.keys(this.fields).forEach((key) => {
      result[key] = this.fields[key].validate();
    });
    return result;
  }
}
