interface IFormField {
  value: any;
  dirty: boolean;
  validate: () => boolean | null;
}

export default class FormField implements IFormField {
  public value: string;
  public dirty = false;
  public validate: () => boolean | null;

  constructor(validate: (value: string, dirty: boolean) => boolean | null, value: string) {
    this.validate = () => {
      return !this.dirty ? null : validate(this.value, this.dirty);
    };
    this.value = value;
  }
}
